const jquery = require('/node_modules/jquery/dist/jquery.min.js');

const open_menu_container = document.getElementsByClassName("open_menu_container")[0];
const open_menu_button = document.getElementsByClassName("open_menu_button")[0];

const close_menu_container = document.getElementsByClassName("close_menu_container")[0];
const close_menu_button = document.getElementsByClassName("close_menu_button")[0];

const menu_about_button = document.getElementsByClassName("menu_about_button")[0];
const menu_subjects_button = document.getElementsByClassName("menu_subjects_button")[0];
const menu_success_stories_button = document.getElementsByClassName("menu_success_stories_button")[0];
const menu_schedule_button = document.getElementsByClassName("menu_schedule_button")[0];


open_menu_button.addEventListener("click", () => {
    open_menu_container.classList.add("hidden");
    close_menu_container.classList.remove("hidden");
});

close_menu_button.addEventListener("click", () => {
    close_menu_container.classList.add("hidden");
    open_menu_container.classList.remove("hidden");
});

menu_about_button.addEventListener("click", () => {
    close_menu_container.classList.add("hidden");
    open_menu_container.classList.remove("hidden");
});

menu_subjects_button.addEventListener("click", () => {
    close_menu_container.classList.add("hidden");
    open_menu_container.classList.remove("hidden");
});

menu_success_stories_button.addEventListener("click", () => {
    close_menu_container.classList.add("hidden");
    open_menu_container.classList.remove("hidden");
});

menu_schedule_button.addEventListener("click", () => {
    close_menu_container.classList.add("hidden");
    open_menu_container.classList.remove("hidden");
});

window.$ = jquery;
window.jQuery = jquery;

$(window).on("scroll", function() {
    var scroll = $(window).scrollTop();
    if (scroll > 30) {
        $("#navbar").addClass("shadow-lg");
    } else {
        $("#navbar").removeClass("shadow-lg");
    }
    if (scroll > 600) {
        $("#about-location").addClass("bg-purple-heart-100 text-purple-heart-500");
    } else {
        $("#about-location").removeClass("bg-purple-heart-100 text-purple-heart-500");
    }
    if (scroll > 2670) {
        $("#about-location").removeClass("bg-purple-heart-100 text-purple-heart-500");
    }
    if (scroll > 2670) {
        $("#subjects-location").addClass("bg-purple-heart-100 text-purple-heart-500");
    } else {
        $("#subjects-location").removeClass("bg-purple-heart-100 text-purple-heart-500");
    }
    if (scroll > 4000) {
        $("#subjects-location").removeClass("bg-purple-heart-100 text-purple-heart-500");
    }
    if (scroll > 4000) {
        $("#success-stories-location").addClass("bg-purple-heart-100 text-purple-heart-500");
    } else {
        $("#success-stories-location").removeClass("bg-purple-heart-100 text-purple-heart-500");
    }
    if (scroll > 5500) {
        $("#success-stories-location").removeClass("bg-purple-heart-100 text-purple-heart-500");
    }
});